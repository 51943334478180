<style type="text/css">
@import "~@/assets/css/common.css";
/* @import "~@/assets/css/admin.css"; */
</style>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import {
  getWebNoticeDetails,
  updateWebNoticeSfyd,
} from "@/api/notice/notice.js";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "系统消息",
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "控制面板",
          active: true,
        },
      ],
      isAdmin: "",
      noticeDetails: {},
    };
  },
  methods: {
    getDetails() {
      getWebNoticeDetails(this.$route.query.noticeId).then((res) => {
        if (res.status) {
          this.noticeDetails = res.data;
        }
      });
    },
    updateWebNoticeSfyd() {
      updateWebNoticeSfyd(this.$route.query.noticeId);
    },
  },
  mounted() {
    this.getDetails();
    this.updateWebNoticeSfyd();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 165px">
          <div class="card-body">
            <div class="news-left">
              <div class="left-top">
                <h3 class="news-title">{{ noticeDetails.noticeTitle }}</h3>
                <div class="top-info">
                  <span style="margin-right: 80px">{{
                    noticeDetails.createTime
                  }}</span>
                  <span style="margin-right: 40px"
                    >来源：系统{{
                      noticeDetails.noticeType == 1 ? "通知" : "公告"
                    }}</span
                  >
                  <span>阅读量：<span class="linspan">1466</span></span>
                  <div class="return-btn">
                    <a href="/admin/list" v-if="isAdmin == 1"
                      ><img
                        src="@/assets/images/person/returnbtn.png"
                        alt=""
                        class="imgin"
                    /></a>
                    <a href="/list" v-else
                      ><img
                        src="@/assets/images/person/returnbtn.png"
                        alt=""
                        class="imgin"
                    /></a>
                  </div>
                </div>
              </div>
              <div class="left-main">
                {{ noticeDetails.noticeContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
